.recent-blog {
	padding-bottom: 50px;
	margin: 0 !important;
}
.latest-blog-header {
	text-align: center;
	margin-bottom: 0 !important;
}
.latest-posts {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;  
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  font-weight: bold;
  text-align: center;
    .post-container {
    padding: 0px 10px;
  }
}
.latest-posts > * {
  padding: 10px;
  flex: 3;
}