footer {
  padding: 2em 0;
  background: $brand-navy;
  color: $light;
  text-align: center;
  a {
    color: $light;
  }
  p {
    color: $light;
    margin: 0;
  }
  .socialIcons li {
    display: inline-block;
    color: $light;
  }

}
@media screen and (min-width: $small) {
  .footer-left {
    float: left;
  }
  .footer-right {
    float: right;
  }
}