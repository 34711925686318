/*
 * Homepage
 */
.home .about {
  text-align: center;
  margin: 40px 0 50px;
  p {
    max-width: 850px;
  }
}
.featured-shop { 
  h3 {
    text-align: center;
    }
  ul.products li.product{
    text-align: center;
  }
}
@media screen and (max-width: $small) {
body.home .wrap.container {
  padding: 0;
  margin: 0;
  width: 100%;
  > .row {
    margin: 0;
  }
  main.main {
    padding: 0;
    margin: 0;
  }
}
  .row {
    margin: 0 5%;
  }
}
@media screen and (min-width: $medium) {
body.home .wrap.container {
  padding: 0;
  margin: 0;
  width: 100%;
  > .row {
    margin: 0;
  }
  main.main {
    padding: 0;
    margin: 0;
  }
}
  .row {
    margin: 0 5%;
  }
}
@media screen and (min-width: $large) {
body.home .wrap.container {
  padding: 0;
  margin: 0;
  width: 100%;
  > .row {
    margin: 0;
  }
  main.main {
    padding: 0;
    margin: 0;
  }
}
  .row {
    margin: 0 5%;
  }
}
