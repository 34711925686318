.gform_wrapper .top_label input.medium, .gform_wrapper .top_label select.medium {
	width: 100% !important;
}
.gform_wrapper .top_label input.small, .gform_wrapper .top_label select.small {
	width: 100% !important;
}
.gform_heading {
	display: none;
}
.gform_wrapper textarea {
	border: 1px solid $brand-navy;
	color: $brand-last;
	width: 100% !important;
	&:focus {
		outline: none !important;
	}
}
.gform_wrapper .top_label .gfield_label {
	color: $brand-navy;
}
.gform_wrapper .gfield_required {
	color: $brand-green;
}
.gform_wrapper input[type=text] {
	border: 1px solid $brand-navy;
	color: $brand-last;
	&:focus {
		outline: none !important;
	}
}
.gform_wrapper li {
	padding-left: 0em !important;
}
.gform_wrapper .ginput_complex label {
	color: $brand-navy;
}
.gform_wrapper select {
	border: 1px solid $brand-navy !important; 
	padding: 6px 5px;
}

.page.page-id-6 {
	.ginput_container.ginput_container_select {
		border: 1px solid $brand-navy !important; 
		padding: 6px 5px;
	}
	.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield select {
		border: none !important;
		outline: none !important;
	}

}