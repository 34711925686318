header.banner {
  text-align: center;
}
header.banner .container {
  padding: 1em 0;
  .header-menu {
    padding-top: 35px;
  }
  .navbar-brand {
    padding: 0;
    height: 100%;
    width: 120px;
    #logo {
      margin: 0;
      height: inherit;
    }
    img {
      max-width: 100%;
      height: auto;
      max-height: 100%;
    }
  }
  .phoneNumber {
    font-size: 1.5em;
    margin: .8em 0 .2em;
  }
  .socialIcons ul {
    margin: 0;
    li {
      display: inline-block;
    }
  }

}

@media screen and (min-width: $medium) {
      .navbar-brand {
        width: 200px !important;
      }
    }

@media screen and (max-width: $small) {
      .navbar-brand {
        float: none;
      }
    }

@media screen and (min-width: $small) {
  header.banner {
    .navbar-brand {
      float: none;
    }
    nav {
      clear: both;
      text-align: center;
    }
  }
}
@media screen and (min-width: $large) {
  header.banner {
    .navbar-brand {
      float: left;
    }
    .header-menu {
      float: right;
    }
    .container {
      position: relative;
    }
    .phoneNumber {
      float: right;
    }
    .socialIcons {
      clear: both;
      text-align: right;
    }
    nav {
      clear: both;
      float: right;
    }
  }
}
nav.main-navigation .nav-menu  {
  li {
    color: $brand-navy !important;
    font-weight: 500;
  }
}
nav.main-navigation button.menu-toggle {
  color: $light !important;
  background-color: $brand-navy !important;
  &:focus {
    outline: none !important;
  }
}
nav.main-navigation .sub-menu {
  color: $light !important;
  background-color: $brand-navy !important;
}


