html {
  font-size: 1em;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

body {
  background-color: $light;
  font-size: 18px;
  font-weight: 100;
  line-height: 1.45;
  color: $brand-last;
  font-family: $font-primary;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
}

p {
  margin-bottom: 1.3em;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 300;
}

h1, h2, h3, h4 {
  margin: 1.414em 0 0.5em;
  font-weight: bold;
  line-height: 1.2;
  font-weight: 300;
  font-family: $font-secondary;
}

h1 {
  margin-top: 0;
  font-size: 3.998em;
}

h2 {font-size: 2.827em;}

h3 {font-size: 1.999em;}

h4 {font-size: 1.414em;}

small, .font_small {font-size: 0.707em;}

li {
  list-style: none;
  padding-left: 1em;
}
ul {
  padding: 0;
}
a {
  color: $brand-last;
}

a:hover {
  color: $brand-accent;
  text-decoration: none !important;
}

/* Changes color of price and stock.
Here until I find a better place to put them */
.woocommerce div.product p.price, 
.woocommerce div.product span.price,
.woocommerce div.product .stock,
.woocommerce ul.products li.product .price  {
  color: $brand-last;
}
