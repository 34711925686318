
@media screen and (min-width: $medium) {
  .hero.jumbotron {
    height: 78vh;
  }
}
section.hero-section.row {
	margin: 0 !important;
	h2 {
	    font-family: $font-primary;
	    font-weight: 300;
	    text-align: center;
	    color: $light;
	}
	.button {
	    text-align: center;
	    color: $light;
	    border-color: $light;
	}
	p {
	    font-family: $font-secondary;
	    font-weight: 300;
	    text-align: center;
	    color: $light;
	    max-width: 650px;
	}
	.hero.jumbotron {
		border-radius: 0 !important;
		position: relative;
		padding-bottom: 0 !important;
		margin-bottom: 0 !important;
		    .hero_unit {
		    	padding-top: 4%;
				text-align: center;
				color: $light;
			}
	}
}