.page.page-id-106{
  .hero_unit {
    h2 {
      margin-bottom: 0em !important;
    }
    p {
      margin-bottom: 2em;
    }
  }
}

@media screen and (max-width: $small) {
body.page.page-id-106 .wrap.container {
  padding: 0;
  margin: 0;
  width: 100%;
  > .row {
    margin: 0;
  }
  main.main {
    padding: 0;
    margin: 0;
  }
}
  .row {
    margin: 0 5%;
  }
}
@media screen and (min-width: $medium) {
body.page.page-id-106 .wrap.container {
  padding: 0;
  margin: 0;
  width: 100%;
  > .row {
    margin: 0;
  }
  main.main {
    padding: 0;
    margin: 0;
  }
}
  .row {
    margin: 0 5%;
  }
}
@media screen and (min-width: $large) {
body.page.page-id-106 .wrap.container {
  padding: 0;
  margin: 0;
  width: 100%;
  > .row {
    margin: 0;
  }
  main.main {
    padding: 0;
    margin: 0;
  }
}
  .row {
    margin: 0 5%;
  }
}