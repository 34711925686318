section.featurettes.row {
	margin: 0 !important;
	.featureMain {
		padding: 0 !important;
	}
	.row.featurette {
		margin: 0 !important;
		padding: 50px 0px;
	}
	.content_block_feature {
		color: $light;
		h3 {
			max-width: 550px;
		    margin-left: auto;
		    margin-right: auto;
		}
	}
}