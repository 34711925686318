.page-header {
  border-bottom: 1px solid $dark;
}
// HR
.hr-single {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid $brand-green;
  width: 50%;
}
// Single Page
.titleSection {
  text-align: center;
  h2 {
    margin: .5em 0em 0.4em 0em;
  }
}
.singleContent {
  margin-bottom: 50px !important;
  p {
    max-width: 100%;
  }
}