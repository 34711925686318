section.panel-hero.row {
  margin: 0 !important;
}
.panel-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;  
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  font-weight: bold;
  text-align: center;
    .panel-container {
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 10px;
      position: relative;
      flex: 1 100%;
      .overlay {
        //background: rgba(2, 56, 103, .2);
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
      }
      a {
        text-decoration: none;
        h2 {
          color: $light;
          margin: 0;
          display: none;
          font-size: 1.9em;
          font-weight: 700;
        }
      }
      &:hover {
        .overlay {
          background: rgba(2, 56, 103, .2);
          h2 {
            display: block;
          }
        }
      }
    }
}

@media screen and (max-width: $small) {
      .panel-container {
        .overlay {
          background: rgba(2, 56, 103, .2);
          h2 {
            display: block !important;
            font-weight: 300 !important;
          }
        }
      }
    }

@media screen and (min-width: $medium) {
      .panel-container {
        flex: 5 !important;
        height: 77vh !important;
      }
      h2 {
          font-size: 2em !important;
          font-weight: 300 !important;
        }
    }

/*.panel-wrap > * {
  flex: 5;
}*/