// Glyphicons font path
$icon-font-path:        "../fonts/";
@font-face {
    font-family: "FuturaExtended";
    src: url("fonts/FuturaExtended.eot?") format("eot"),
         url("fonts/FuturaExtended.woff") format("woff"),
         url("fonts/FuturaExtended.ttf") format("truetype"),
         url("fonts/FuturaExtended.svg") format("svg");
}

// Fonts
$font-primary: 'Futura', sans-serif;
$font-secondary: 'Futura', sans-serif;

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #E6E2AF;
$brand-secondary:       #A7A37E;
$brand-complementary:   #EFECCA;
$brand-accent:          #046380;
$brand-last:            #002F2F;

// Kyle Additions
$brand-white: #F2F2F2;
$brand-gray: #999999;
$brand-green: #669D35;
$brand-blue: #0E76BD;
$brand-navy: #023867;

$dark: #333;
$light: white;
$gray: #777;
$lightgray: #999999;

// Media Query Sizes
$small: 600px;
$medium: 900px;
$large: 1200px;

// Menu & Header helpers
$header-nav-zindex: 999;

// Element Colors
$text_color: $brand-primary;
$link_color: $brand-accent;
$border_color: $brand-accent;