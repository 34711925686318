.button, .woocommerce a.button,
.woocommerce div.product form.cart .button,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce .cart .button, .woocommerce .cart input.button,
.woocommerce #payment #place_order, .woocommerce-page #payment #place_order {
 transition: 400ms;
 background: transparent;
 border: 2px solid $brand-navy;
 color: $brand-navy;
 font-weight: 100;
 padding: 10px 20px;
 font-size: 1.2em;
 position: relative;
 border-radius: 0;
 line-height: 1.4em;
 outline: none !important;
 &:hover {
   background: $brand-green;
   color: $light;
   border: 1px solid $brand-green;
 }
}
