.cta {
	position: relative;
	.overlay-cta {
		background-color: rgba(14, 118, 189, 0.6);
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	}
	h2 {
		color: $light;
	}
	.ctaContent {
    	text-align: center;
    	margin-bottom: 80px !important;
	    margin-top: 20px !important;
	    .headerCopyCta {
	    	margin: 1.414em 0 .7em;
	    }
	    .subCopyCta {
	    	margin: .414em 0 .5em;
	    }
	    .button {
	    	border: 2px solid $light;
	    	color: $light;
	    }
    }
}
section.call-to-action.row {
	margin: 0 !important;
}